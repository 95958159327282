import BasePlugin from '../BasePlugin'

export default class ExportProjectSolutionFromPlanningDashboard extends BasePlugin {
  async execute () {
    let me = this
    let context = this.context
    let dashboard = this.context.getDashboardComponents()['component_c9402bdc-78d1-40eb-94d7-9c922f755d7d'][0]
    context.$loading(true)
    let ids = []
    dashboard.data.forEach(function (item) {
      ids.push(item['id_проекта_решения'])
    })
    context.$http({
      method: 'post',
      url: `${context.$config.api}/etleditor/export/103`,
      data: {
        ids
      },
      responseType: 'arraybuffer'
    }).then(function (response2) {
      context.$loading().close()
      me.openExportAttachment(response2.data, me, 'xlsx')
    }, (response2) => { context.$loading().close() })
  }
}
